/* eslint-disable import/no-extraneous-dependencies */
import React, {useEffect, useRef, useState} from 'react';
import {useRouter} from 'next/router';
import {background} from 'styled-system';
import {Typography} from '../typography';
import {Box, AnimatedBox} from '../box';
import {FrameworkImage} from '../image-renderer';
import {NFTTrackCardProps} from './types';
import {Pressable} from '../pressable';
import {useNFTTrackCardAnimation} from './useNFTTrackCardAnimation';
import {CardBottomDetails} from './card-bottom-details';
import {ArrowRightSolidIcon} from '../icons';
import {useSpring} from '../animation';

export const NFTTrackCard: React.FC<NFTTrackCardProps> = ({
  src,
  trackName,
  artist,
  price,
  currencySymbol,
  network,
  state,
  totalSupply,
  openseaUrl,
  onPress,
  ownedQty,
  tokenId,
  multiCopy,
  isVideo,
  isMember = false,
  hasBorder,
  description,
  hideCurrency,
  ...rest
}) => {
  const [scrollWidth, setScrollWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);
  const [artistTextScrollWidth, setArtistTextScrollWidth] = useState(0);
  const [artistTextWidth, setArtistTextWidth] = useState(0);
  const [imageLoading, setImageLoading] = useState(true);
  const router = useRouter();
  const {pathname} = router;

  const overflowTrackName = scrollWidth > textWidth;
  const overflowArtistName = artistTextScrollWidth > artistTextWidth;
  const trackNameRef = useRef<any>(null);
  const artistNameRef = useRef<any>(null);

  const {
    hoveredStyle,
    events,
    hovered,
    textScrollStyle,
    artistTextScrollStyle,
  } = useNFTTrackCardAnimation({
    scrollWidth,
    textWidth,
    artistTextScrollWidth,
    artistTextWidth,
    startOpacity: 0.84,
  });

  useEffect(() => {
    setTextWidth(trackNameRef.current?.offsetWidth);
    setScrollWidth(trackNameRef.current?.scrollWidth);
    setArtistTextWidth(artistNameRef.current?.offsetWidth);
    setArtistTextScrollWidth(artistNameRef.current?.scrollWidth);
  }, [totalSupply, trackName, artist]);

  const widthAnimation = useSpring({
    width: hovered ? '68px' : '24px',
    config: {
      duration: 300,
    },
  });

  const textAnimation = useSpring({
    opacity: hovered ? 1 : 0,
    config: {
      duration: 300,
    },
  });

  const nftCard = () => {
    switch (pathname) {
      case '/discover':
      case '/':
        return (
          <Box position="relative" top="0" left="0" width="100%" height="100%">
            {imageLoading && (
              <Box
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    'linear-gradient(110deg, #121212 8%, #1e1e1e 18%, #121212 33%)',
                  backgroundSize: '200% 100%',
                  animation: 'shimmer 1.5s linear infinite',
                }}
              />
            )}
            <FrameworkImage
              src={src}
              alt={src}
              width={377}
              height={377}
              quality={40}
              onLoadStart={() => setImageLoading(true)}
              onLoad={() => setImageLoading(false)}
              style={{
                opacity: imageLoading ? 0 : 1,
                transition: 'opacity 0.3s ease-in-out',
              }}
            />
          </Box>
        );

      default:
        return (
          <Box position="absolute" top="0" left="0" width="100%" height="100%">
            {imageLoading && (
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                style={{
                  background:
                    'linear-gradient(110deg, #121212 8%, #1e1e1e 18%, #121212 33%)',
                  backgroundSize: '200% 100%',
                  animation: 'shimmer 1.5s linear infinite',
                }}
              />
            )}
            <FrameworkImage
              src={src}
              alt={src}
              layout="fill"
              objectFit="cover"
              onLoadStart={() => {
                setImageLoading(true);
              }}
              onLoad={() => {
                setImageLoading(false);
              }}
              onError={() => {
                setImageLoading(false);
              }}
              style={{
                opacity: imageLoading ? 0 : 1,
                transition: 'opacity 0.3s ease-in-out',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          </Box>
        );
    }
  };

  const style = !['/discover', '/'].includes(pathname)
    ? {paddingTop: '100%'}
    : {};

  return (
    <Pressable {...events} onPress={onPress}>
      <AnimatedBox
        width="100%"
        overflow="hidden"
        borderStyle="solid"
        borderWidth={hasBorder ? 'two' : 'zero'}
        borderColor="gray"
        style={{
          ...hoveredStyle,
        }}
      >
        <Box
          borderBottomStyle="solid"
          borderBottomWidth="one"
          borderBottomColor="outlines"
          style={style}
        >
          {src ? nftCard() : null}
        </Box>
        {isVideo && (
          <AnimatedBox
            alignItems="center"
            flexDirection="row"
            style={{
              position: 'absolute',
              zIndex: 1,
              top: '15px',
              left: '15px',
              borderRadius: 20,
              height: '18px',
              backgroundColor: 'hsla(210, 50%, 1%, 0.6)',
              ...widthAnimation,
            }}
          >
            <Box
              style={{
                marginTop: '4px',
                marginBottom: '4px',
                marginLeft: '8px',
                marginRight: '4px',
              }}
            >
              <ArrowRightSolidIcon size={10} color="outlines" />
            </Box>
            <AnimatedBox style={{...textAnimation}}>
              <Typography
                color="outlines"
                lineHeight="s"
                fontSize="s"
                fontFamily="abc"
              >
                video
              </Typography>
            </AnimatedBox>
          </AnimatedBox>
        )}
        {isMember &&
          state !== 'traxxStemz' &&
          state !== 'membership' &&
          state !== 'emilg' && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1,
                bottom: '110px',
                left: '15px',
                borderRadius: 18,
                height: '18px',
                width: '83px',
                textAlign: 'center',
                background:
                  'linear-gradient(270deg, #C3AE7E 0%, #EFE9DC 23.96%, #C3AE7E 49.48%, #C3AE7E 100%)',
              }}
            >
              <Typography lineHeight="s" fontSize="s" fontFamily="abc">
                Discount
              </Typography>
            </div>
          )}
        <Box margin="two" overflow="hidden">
          <AnimatedBox style={overflowTrackName ? textScrollStyle : null}>
            <Typography
              color="textDefault"
              textStyle="s"
              whiteSpace="nowrap"
              ref={trackNameRef}
            >
              {trackName || ' '}
            </Typography>
          </AnimatedBox>
          <AnimatedBox
            style={overflowArtistName ? artistTextScrollStyle : null}
          >
            <Typography
              color="textLow"
              textStyle="s"
              whiteSpace="nowrap"
              ref={artistNameRef}
            >
              {artist || ' '}
            </Typography>
          </AnimatedBox>
          {overflowTrackName || overflowArtistName ? (
            <Box
              position="absolute"
              right="zero"
              top="zero"
              bottom="zero"
              width={10}
              css={{
                background:
                  'linear-gradient(to right, rgba(1,2,3,0) , rgba(1,2,3,0.85))',
              }}
            />
          ) : null}
        </Box>
        <Box marginX="two" pb="two">
          <CardBottomDetails
            price={price}
            currencySymbol={currencySymbol}
            network={network}
            state={state}
            totalSupply={totalSupply}
            openseaUrl={openseaUrl}
            ownedQty={ownedQty}
            multiCopy={multiCopy}
            tokenId={tokenId}
            isHide={
              trackName === 'Low Lives [Conflict Theory remix]' ||
              trackName === 'Stamps'
            }
            hideCurrency={hideCurrency}
          />
        </Box>
        {description && (
          <Box marginX="two" pb="two">
            <Typography textStyle="s" color="textDefault">
              {description}
            </Typography>
          </Box>
        )}
        <style jsx global>{`
          @keyframes shimmer {
            0% {
              background-position: -200% 0;
            }
            100% {
              background-position: 200% 0;
            }
          }
        `}</style>
      </AnimatedBox>
    </Pressable>
  );
};
